import React, { useEffect, useState } from "react";

import { SubHeading, MenuItem, Hamburger } from "../../components";
import "./Menu.css";
import Modal from 'react-modal';
import { urlFor,getBurgers, getExtras, getDrinks, getAlcohols,getSnacks, } from "../../client";
import LanguageSwitch, { language } from "../../components/LanguageSwitch/LanguageSwitch";

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)', // Black transparent background
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    position: 'relative',
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: 'auto',
    alignItems: 'center',
    background:"#000000",
    backgroundColor:"black",
    justifyContent: 'center',
    maxWidth: '600px', // Optional: Adjust the maximum width of the modal content
    maxHeight: '80vh', // Optional: Adjust the maximum height of the modal content
    margin: 'auto',
    border: 'none',
    borderRadius: '16px',
    padding: '20px',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.6)',
  },
};

const Menu = () => {
const [burgers,setBurgers ] = useState([]);
const [extras,setExtras ] = useState([]);
const [alcohols,setAlcohols ] = useState([]);
const [drinks,setDrinks ] = useState([]);
const [snacks,setSnacks ] = useState([]);
const [openModal,setOpenModal] = useState(true);
const [currentLanguage, setCurrentLanguage] = useState('TR');

  const handleLanguageChange = (selectedLanguage) => {
    setCurrentLanguage(selectedLanguage);
  };

const getData = async () => {
  let burger = await getBurgers();
  setBurgers(burger);
  let extra = await getExtras();
  setExtras(extra);
  let drink = await getDrinks();
  setDrinks(drink);
  let alcohol = await getAlcohols();
  setAlcohols(alcohol);
  let snack = await getSnacks();
  console.log(snack)
  setSnacks(snack)
}

useEffect(() => {
  getData();
},[])

Modal.setAppElement('#root');


  return (
    <div className="app__specialMenu flex__center section__padding" id="menu">
      <LanguageSwitch
        currentLanguage={currentLanguage}
        onLanguageChange={handleLanguageChange}
      />
      <Modal
        isOpen={openModal}
        style={customStyles}
        shouldCloseOnOverlayClick={true}
      >
        <p className="warning__cormorant" style={{color:"red",fontWeight:"bold"}}>Herhangi bir ürüne alerjiniz varsa sipariş öncesinde lütfen belirtiniz.</p>
      <button onClick={()=> setOpenModal(false)} type="button" className="custom__button" style={{alignSelf:"center",marginTop:15}}>Menüye Devam Et</button>

      </Modal>
      <div className="app__specialMenu-title">
        <SubHeading title="Omnia Story Burger Menü" />
        <h1 className="headtext__cormorant">Story's Burger</h1>
      </div>

      <div className="app__specialMenu-menu_wine  flex__center">
        <p className="app__specialMenu-menu_heading">Hamburgerler</p>
        <div className="app__specialMenu_menu_items">
          {burgers.map((burger, index) => (
            <Hamburger
              key={burger.title + index}
              title={burger.title}
              img={urlFor(burger.image)}
              price={burger.price}
              ingredients={currentLanguage === "TR" ? burger.ingredients : burger.ingredientsEn}
              description={currentLanguage === "TR" ? burger.description : burger.descriptionEn}
            />
          ))}
        </div>
      </div>
      <div className="app__specialMenu-menu_wine  flex__center">
        <p className="app__specialMenu-menu_heading">Atıştırmalıklar</p>
        <div className="app__specialMenu_menu_items">
          {snacks.map((snack, index) => (
            <Hamburger
              key={snack.title + index}
              title={snack.title}
              img={urlFor(snack.image)}
              price={snack.price}
              description={snack.description}
            />
          ))}
        </div>
      </div>
      <div className="app__specialMenu-menu">
        <div className="app__specialMenu-menu_cocktails  flex__center">
          <p className="app__specialMenu-menu_heading">Ekstralar</p>
          <div className="app__specialMenu_menu_items">
            {extras.map((extra, index) => (
              <MenuItem
                key={extra.title + index}
                title={extra.title}
                price={extra.price}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="app__specialMenu-menu">
        <div className="app__specialMenu-menu_cocktails  flex__center">
          <p className="app__specialMenu-menu_heading">Alkollü İçecekler</p>
          <div className="app__specialMenu_menu_items">
            {alcohols.map((alcohol, index) => (
              <MenuItem
                key={alcohol.title + index}
                title={alcohol.title}
                price={alcohol.price}
              />
            ))}
          </div>
        </div>

        <div className="app__specialMenu-menu_img" />

        <div className="app__specialMenu-menu_cocktails  flex__center">
          <p className="app__specialMenu-menu_heading">Alkolsüz İçecekler</p>
          <div className="app__specialMenu_menu_items">
            {drinks.map((drink, index) => (
              <MenuItem
                key={drink.title + index}
                title={drink.title}
                price={drink.price}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Menu;
