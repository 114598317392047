import imageUrlBuilder from "@sanity/image-url";
import sanityClient from "@sanity/client";

export const client = sanityClient({
  projectId: "tnfow3dq",
  dataset: "production",
  useCdn: true,
  apiVersion: "2022-01-12",
  token: process.env.REACT_APP_SANITY_TOKEN,
});

export async function getBurgers() {
  const posts = await client.fetch('*[_type == "hamburger"]');
  return posts;
}

export async function getAlcohols() {
  const posts = await client.fetch('*[_type == "alcohol"]');
  return posts;
}

export async function getExtras() {
  const posts = await client.fetch('*[_type == "extra"]');
  return posts;
}

export async function getDrinks() {
  const posts = await client.fetch('*[_type == "drink"]');
  return posts;
}

export async function getPosts() {
  const posts = await client.fetch('*[_type == "post"]');
  return posts;
}

export async function getSnacks() {
  const snacks = await client.fetch('*[_type == "snack"]');
  return snacks;
}



const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);
